import styled from "styled-components";

export const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: contain;
  scale: 1.8;
  margin-right: 50px;
  @media screen and (max-width: 530px) {
    height: 80px;
  }
  @media screen and (max-width: 450px) {
    scale: 1;
    margin: 0;
    height:${(props) => props.height};
  }
`;
