import { useContext } from "react";
import { Logo } from "../logo";
import { HeaderStyled } from "./styles";
import { UserContext } from "../../context/userContext";
import { Button } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";

export const Header = () => {
  const { user, logOutUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isHome = location.pathname === "/";
  return (
    <HeaderStyled>
      <div>
        <div className="USER__INFO">
          <h1>Olá, {user && user.name} </h1>
          <button onClick={() => {
            logOutUser()
            navigate('/login')
          }}>
            <FaSignOutAlt />
          </button>
        </div>
        {user && user.group == "ADMIN" && isHome && (
          <div className="flex-row">
            <Button
              outline
              onClick={() => {
                navigate("/create_property");
              }}
            >
              Criar
            </Button>

            <Button
              outline
              onClick={() => {
                navigate("/admin");
              }}
            >
              Admin
            </Button>
          </div>
        )}
        {user && user.group == "EMPLOYEES" && isHome && (
          <div className="flex-row">
            <Button
              outline
              onClick={() => {
                navigate("/create_property");
              }}
            >
              Criar
            </Button>
          </div>
        )}
      </div>
      <Logo />
    </HeaderStyled>
  );
};
