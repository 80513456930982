import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../header";
import { UserContext } from "../../../context/userContext";
import { cpfMask, numbersCpf } from "../../../utils/cpfMask";
import { normalizeCnpjNumber, numbersCnpj } from "../../../utils/cnpjMask";

export const EmployeeId = ({}) => {
  const navigate = useNavigate()
  const { getUserById, deleteUser, updateUser, createPassMail } = useContext(UserContext);
  const { employee_id } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [user, setUser] = useState({
    email: "",
    name: "",
    cpf: "",
    cnpj: "",
    firstName: "",
    updatedAt: "",
    group: "",
    passwordEmail:""
  });


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (employee_id) {
      getUserById(employee_id).then((res) =>
        setUser({
          ...res,
          cpf: cpfMask(user.cpf),
          cnpj: normalizeCnpjNumber(user.cnpj),
        })
      );
    }
  }, [employee_id]);

  const formik = useFormik({
    onSubmit: async (values) => {
      values.cnpj = numbersCnpj(values.cnpj);
      values.cpf = numbersCpf(values.cpf);
      values.group = values.group === "PARTNER" ? 3 : 2;
      updateUser(employee_id, values).then((res) => setUser(res));
      if (isChecked) {
        await createPassMail(user.id, {password: user.password})
      }
      navigate('/admin/employees')
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        email: user.email,
        name: user.name,
        cpf: cpfMask(user.cpf),
        cnpj: normalizeCnpjNumber(user.cnpj),
        firstName: user.firstName,
        updatedAt: user.updatedAt,
        group: user.group,
      });
    }
  }, [user]);

  return (
    <>
      <Header></Header>
      <Container>
        <Row>
          <Col xs="12 pb-3">
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 align-center"
            >
              {user && (
                <>
                  <div>
                    <label>Nome</label>
                    <Input
                      value={user.name}
                      onChange={(e) => {
                        setUser({ ...user, name: e.target.value });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Email</label>
                    <Input
                      value={user.email}
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Cpf</label>
                    <Input
                      value={user.cpf}
                      type="tel"
                      onChange={(e) => {
                        setUser({ ...user, cpf: cpfMask(e.target.value) });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Cnpj</label>
                    <Input
                      value={user.cnpj}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          cnpj: normalizeCnpjNumber(e.target.value),
                        });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Grupo</label>
                    <select
                      value={user.group}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setUser({ ...user, group: e.target.value });
                      }}
                    >
                      <option value={"PARTNER"}>Parceiro</option>
                      <option value={"EMPLOYEES"}>Funcionario</option>
                    </select>
                  </div>
                  <div>
                    <label>Primeiro nome</label>
                    <Input
                      value={user.firstName}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                      }}
                    ></Input>
                  </div>
                </>
              )}
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ marginRight: '5px' }}
                  />
                  Senha automação de email
                </label>

                {isChecked && (
                  <div style={{ marginTop: '10px' }}>
                    <label htmlFor="password">Senha</label>
                    <Input type="text" 
                      name="password"
                      value={user.passwordEmail}
                      onChange={(e) => {
                        setUser({...user, password: e.target.value});
                      }} />
                  </div>
                )}
              </div>

              <Button size="lg" type="submit" color="danger">
                Atualizar
              </Button>
              <Button
                size="lg"
                onClick={() => {
                  deleteUser(employee_id).then(() => {
                    navigate('/admin/employees')
                  });
                }}
                color="danger"
              >
                Deletar
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
