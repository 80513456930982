export const cpfMask = value => {
  if (!value) return "";
  return value
    .replace(/\D/g, '') 
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
}

export const numbersCpf = (value) => {
  if (!value) return "";
  return value.replaceAll('.', "").replace('-', '')
}