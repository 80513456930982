import { createContext, useContext, useEffect, useState } from "react";
import { API } from "../api";
import { UserContext } from "./userContext";
import camelcaseKeysDeep from "camelcase-keys-deep";
import snakecaseKeys from "snakecase-keys";

export const PropertyContext = createContext({});

export const PropertyProvider = ({ children }) => {
  const { token } = useContext(UserContext);

  const [properties, setProperties] = useState();

  const getProperties = async () => {
    const res = await API.get("/property", {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => {

      setProperties(camelcaseKeysDeep(data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })))
    });
    return res;
  };

  const getPropertyById = async (property_id) => {
    const res = await API.get(`/property/${property_id}`, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const createProperty = async (data) => {
    data = snakecaseKeys(data)
    const res = await API.post(`/property`, data, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => {
      camelcaseKeysDeep(data)
      getProperties()
    });
    return res;
  };
  const patchProperty = async (property_id, data) => {
    data = snakecaseKeys(data)
    const res = await API.patch(`/property/${property_id}`, data, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => {
      camelcaseKeysDeep(data)
      getProperties()
    });
    return res;
  };

  const deleteProperty = async (property_id) => {
    const res = await API.delete(`/property/${property_id}`, {
      headers: { Authorization: `Token ${token}` },
    }).then(getProperties);
    return res;
  };

  useEffect(() => {
    if (token && token !== "undefined") {
      getProperties()
    }

  }, [token]);

  return (
    <PropertyContext.Provider
      value={{
        getProperties,
        getPropertyById,
        properties,
        setProperties,
        deleteProperty,
        patchProperty,
        createProperty
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
