import styled from "styled-components";
import image_login from "../../assets/image_login.png";

export const Container = styled.main`
  background-image: url(${image_login});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: 1366px) {
    justify-content: center;
  }
`;

export const ContainerLogin = styled.form`
  position: relative;

  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.8px;

  width: 500px;
  height: 240px;
  border-radius: 30px;
  gap: 15px;

  button {
    position: absolute;
    right: 92%;
    top: 10%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 8px solid #31225c;
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 22%;
  }
  input {
    width: 80%;
    height: 60px;
    border-radius: 40px;
    background-color: #ed9291;
    border: 0;
    padding-left: 30px;
    color: #fff;
  }
  label {
    margin-left: 8%;
  }
  .send_mobile {
    display: none;
  }
  @media screen and (max-width: 1366px) {
    max-width: 50%;
    margin: 0 auto;
    input {
      height: 60px;
    }
  }
  @media screen and (max-width: 600px) {
    height: max-content;
    gap:50px;
    width: 90%;
    padding: 20px;
    gap: 0px;
    max-width: none;
    .lock {
      display: none;
    }
    .send_mobile {
      display: flex;
      width: 50%;
      height: 50px;
      margin-top: 30px;
    }
    button {
      background-color: #ed9291;
      color: #fff;
      width: 90%;
      height: 40px;
      border-radius: 30px;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
    }
    div {
      align-items: center;
      margin: 0;
    }
    input {
      width: 90%;
      height: 40px;
    }
    label {
      width: 90%;
    }
  }
`;

export const Logo = styled.img`
  width: 500px;
  margin: 80px 0;
  @media screen and (max-width: 1366px) {
    scale: 1.2;
    margin: 40px;
  }
  @media screen and (max-width: 612px) {
    width: 80% !important;
    margin: 20px 0;
  }
`;
export const Modal = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.2);
  align-items: center;
  justify-content: flex-start;
`;
