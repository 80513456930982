import styled from "styled-components";

export const FooterStyled = styled.footer`
  display: flex;
  margin-top: auto;
  text-align: center;
  justify-content: center;
  h1 {
    font-weight: 400;
    font-size: 16px !important;
  }
`;
