import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../header";
import { PropertyContext } from "../../../context/propertyContext";
import { CurrencyEvent, formatValueBrl, getValueCurrency } from "../../currencyInput.js";
import { cpfMask } from "../../../utils/cpfMask.js";
import { UserContext } from "../../../context/userContext.js";
import { isEmpty } from "lodash";

export const PropertyEmployeeId = ({}) => {
  const navigate = useNavigate();
  const { route_id } = useParams();
  const [users, setUsers] = useState([]);
  const [property, setProperty] = useState({
    name: "",
    cpf: "",
    status: "",
    responsible: "",
    propertyValue: "",
    amountFinanced: "",
    proposalNumber: "",
    typeOfOperation: "",
    bank: "",
    phase: "",
    employee: "",
    observation: "",
  });
  const { getPropertyById, patchProperty } = useContext(PropertyContext);
  const { filterUsers } = useContext(UserContext);

  useEffect(() => {
    filterUsers().then(setUsers);
  }, []);

  useEffect(() => {
    if (!isEmpty(route_id)) {
      getPropertyById(route_id).then((res) =>
        setProperty({
          ...res,
          cpf: cpfMask(res.cpf),
          propertyValue: formatValueBrl(res.propertyValue),
          amountFinanced: formatValueBrl(res.amountFinanced),
          employee: res.employee.id,
          responsible: res.responsible.id,
        })
      );
    }
  }, [route_id]);

  const formik = useFormik({
    onSubmit: async (values) => {
      values.propertyValue = getValueCurrency(values.propertyValue);
      values.amountFinanced = getValueCurrency(values.amountFinanced);
      patchProperty(route_id, values).then((res) =>navigate('/'));
    },
  });

  useEffect(() => {
    if (property) {
      formik.setValues({
        name: property.name,
        cpf: property.cpf,
        status: property.status,
        responsible: property.responsible,
        employee: property.employee,
        propertyValue: property.propertyValue,
        amountFinanced: property.amountFinanced,
        proposalNumber: property.proposalNumber,
        typeOfOperation: property.typeOfOperation,
        bank: property.bank,
        phase: property.phase,
        observation: property.observation,
      });
    }
  }, [property]);

  return (
    <>
      <Header></Header>
      <Container responsive="true">
        <Row>
          <Col xs="12 pb-3">
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 align-center"
            >
              <div>
                <label>Nome</label>
                <Input
                  id="name"
                  name="name"
                  value={property.name}
                  type="text"
                  onChange={(e) => {
                    setProperty({ ...property, name: e.target.value });
                  }}
                />
              </div>
              <div>
                <label>Cpf</label>
                <Input
                  id="cpf"
                  name="cpf"
                  value={property.cpf}
                  type="text"
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      cpf: cpfMask(e.target.value),
                    });
                  }}
                />
              </div>
              <div>
                <label>Status</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="status"
                  name="status"
                  value={property.status}
                  type="text"
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      status: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  <option value="Aprovado">Aprovado</option>
                  <option value="Reprovado">Reprovado</option>
                </select>
              </div>
              <div>
                <label>Parceiro</label>
                <select
                  name="responsible"
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProperty({ ...property, responsible: e.target.value });
                  }}
                  value={property.responsible}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  {users.partners &&
                    users.partners.map((partner, i) => {
                      return (
                        <option key={i} value={partner.id}>
                          {partner.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label>Valor do imóvel</label>
                <Input
                  id="propertyValue"
                  name="propertyValue"
                  value={property.propertyValue}
                  type="text"
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      propertyValue: CurrencyEvent(e),
                    });
                  }}
                />
              </div>
              <div>
                <label>Financiado</label>

                <Input
                  id="amountFinanced"
                  name="amountFinanced"
                  value={property.amountFinanced}
                  type="text"
                  onChange={(e) => {
                    CurrencyEvent(e);
                    setProperty({
                      ...property,
                      amountFinanced: CurrencyEvent(e),
                    });
                  }}
                />
              </div>
              <div>
                <label>Modalidade</label>
                {/* <Input
                    id="typeOfOperation"
                    name="typeOfOperation"
                    value={formik.values.typeOfOperation}
                    type="text"
                    onChange={formik.handleChange}
                  /> */}
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="typeOfOperation"
                  name="typeOfOperation"
                  value={property.typeOfOperation}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      typeOfOperation: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  <option value="CGI">CGI</option>
                  <option value="Imóvel pronto">Imóvel pronto</option>
                  <option value="Construção">Construção</option>
                  <option value="Lote">Lote</option>
                </select>
              </div>
              <div>
                <label>Banco</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="bank"
                  name="bank"
                  value={property.bank}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      bank: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  <option value="Caixa">Caixa</option>
                  <option value="Bradesco">Bradesco</option>
                  <option value="BRB">BB</option>
                  <option value="Itau">Itau</option>
                  <option value="Santander">Santander</option>
                  <option value="Inter">Inter</option>
                   
                </select>
              </div>
              {/* <div>
                  <label>Data</label>
                  <Input
                    id="sendDate"
                    name="sendDate"
                    value={property.sendDate}
                    type="text"
                    onChange={(e) => {
                      setProperty({ ...property, sendDate: e.target.value });
                    }}
                  />
                </div> */}
              <div>
                <label>Etapa</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="phase"
                  value={property.phase}
                  onChange={(e) => {
                    setProperty({ ...property, phase: e.target.value });
                  }}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  <option value="Avaliação">Avaliação</option>
                  <option value="Vistoria">Vistoria</option>
                  <option value="Jurídico">Jurídico</option>
                  <option value="Conformidade">Conformidade</option>
                  <option value="Emissão do Contrato">
                    Emissão do Contrato
                  </option>
                  <option value="Registro">Registro</option>
                </select>
              </div>
              <div>
                <label>Colaborador</label>
                <select
                  name="employee"
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProperty({ ...property, employee: e.target.value });
                  }}
                  value={property.employee}
                >
                  <option value="" disabled defaultValue>
                    Selecione
                  </option>
                  {users.employees &&
                    users.employees.map((employee, i) => {
                      return (
                        <option key={i} value={employee.id}>
                          {employee.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label>Observação</label>
                <Input
                  id="observation"
                  name="observation"
                  value={property.observation}
                  type="text"
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      observation: e.target.value,
                    });
                  }}
                />
              </div>
              <Button size="lg" type="submit" color="danger">
                Atualizar
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
