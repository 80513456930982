import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../header";
import { PropertyContext } from "../../../context/propertyContext";
import { cpfMask, numbersCpf } from "../../../utils/cpfMask";
import {
  CurrencyEvent,
  formatValueBrl,
  getValueCurrency,
} from "../../currencyInput.js";
import { UserContext } from "../../../context/userContext.js";

export const PropertyCreate = ({}) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const { createProperty } = useContext(PropertyContext);
  const { filterUsers } = useContext(UserContext);

  useEffect(() => {
    filterUsers().then(setUsers);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      cpf: "",
      status: "",
      responsible: "",
      propertyValue: "",
      amountFinanced: "",
      proposalNumber: "",
      typeOfOperation: "",
      bank: "",
      phase: "",
      employee: "",
      observation: "",
    },
    onSubmit: async (values) => {
      values.cpf = numbersCpf(values.cpf);
      values.propertyValue = getValueCurrency(values.propertyValue);
      values.amountFinanced = getValueCurrency(values.amountFinanced);
      return createProperty(values).then(res=> navigate('/'))
    },
  });

  return (
    <>
      <Header></Header>
      <Container responsive={true}>
        <Row>
          <Col xs="12 pb-3">
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 align-center"
            >
              <>
                <div>
                  <label>Nome</label>
                  <Input
                    id="name"
                    name="name"
                    value={formik.values.name}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <label>Cpf</label>
                  <Input
                    id="cpf"
                    name="cpf"
                    value={formik.values.cpf}
                    type="text"
                    onChange={(e) => {
                      e.target.value = cpfMask(e.target.value)
                      formik.handleChange(e)
                    }}
                  />
                </div>
                <div>
                  <label>Status</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="status"
                    name="status"
                    value={formik.values.status}
                    type="text"
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    <option value="Aprovado">Aprovado</option>
                    <option value="Reprovado">Reprovado</option>
                  </select>
                </div>
                <div>
                  <label>Parceiro</label>
                  <select
                    name="responsible"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={formik.handleChange}
                    value={formik.values.responsible}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    {users.partners &&
                      users.partners.map((partner, i) => {
                        return (
                          <option key={i} value={partner.id}>
                            {partner.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  <label>Valor do imóvel</label>
                  <Input
                    id="propertyValue"
                    name="propertyValue"
                    value={formik.values.propertyValue}
                    type="text"
                    onChange={(e) => {
                      CurrencyEvent(e);
                      formik.handleChange(e);
                    }}
                  />
                </div>
                <div>
                  <label>Financiado</label>

                  <Input
                    id="amountFinanced"
                    name="amountFinanced"
                    value={formik.values.amountFinanced}
                    type="text"
                    onChange={(e) => {
                      CurrencyEvent(e);
                      formik.handleChange(e);
                    }}
                  />
                </div>
                <div>
                  <label>Nº Proposta</label>

                  <Input
                    id="proposalNumber"
                    name="proposalNumber"
                    value={formik.values.proposalNumber}
                    type="number"
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <label>Modalidade</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="typeOfOperation"
                    name="typeOfOperation"
                    value={formik.values.typeOfOperation}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    <option value="CGI">CGI</option>
                    <option value="Imóvel pronto">Imóvel pronto</option>
                    <option value="Construção">Construção</option>
                    <option value="Lote">Lote</option>
                  </select>
                </div>
                <div>
                  <label>Banco</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="bank"
                    name="bank"
                    value={formik.values.bank}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    <option value="Caixa">Caixa</option>
                    <option value="Bradesco">Bradesco</option>
                    <option value="BRB">BB</option>
                    <option value="Itau">Itau</option>
                    <option value="Santander">Santander</option>
                     
                  </select>
                </div>
                <div>
                  <label>Data</label>
                  <Input
                    id="sendDate"
                    name="sendDate"
                    value={formik.values.sendDate}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <label>Etapa</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="phase"
                    value={formik.values.phase}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    <option value="Avaliação">Avaliação</option>
                    <option value="Vistoria">Vistoria</option>
                    <option value="Jurídico">Jurídico</option>
                    <option value="Conformidade">Conformidade</option>
                    <option value="Emissão do Contrato">
                      Emissão do Contrato
                    </option>
                    <option value="Registro">Registro</option>
                  </select>
                </div>
                <div>
                  <label>Colaborador</label>
                  <select
                    name="employee"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={formik.handleChange}
                    value={formik.values.employee}
                  >
                    <option value="" disabled defaultValue>
                      Selecione
                    </option>
                    {users.employees &&
                      users.employees.map((employee, i) => {
                        return (
                          <option key={i} value={employee.id}>
                            {employee.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  <label>Observação</label>
                  <Input
                    id="observation"
                    name="observation"
                    value={formik.values.observation}
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </>
              <Button size="lg" type="submit" color="success" outline>
                Criar
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
