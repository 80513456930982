import { Footer } from "../footer";
import { Header } from "../header";
import { Properties } from "../properties";

export const EmployeeHome = ({ user }) => {
  return (
    <>
      <Header user_name={user.name} />
      <main>
        <Properties group={user.group} />
      </main>
      <Footer />
    </>
  );
};
