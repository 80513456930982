import styled from "styled-components";

export const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  .USER__INFO{
    display: flex;
    align-items: baseline;
    gap: 8px;
    button{
      border: 0;
      width: 0;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 20px;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
    .USER__INFO{
      flex-direction: row;
      margin-bottom: 10px;
    }
    h1{
      margin-bottom: 0;
    }
    div{
      display: flex;
      flex-direction: column;
    }
    button {
      align-self: center;
    }
  }
`;
