import { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { ButtonSearch } from "./styles";
import { CiSearch } from "react-icons/ci";
import { Button } from "reactstrap";
import { cpfMask } from "../../utils/cpfMask";

export const Employees = ({}) => {
  const [employeesList, setEmployeeList] = useState([]);
  const [employeeListFiltered, setEmployeeListFiltered] = useState([]);
  const [input, setInput] = useState("");
  const { filterUsers } = useContext(UserContext);
  const navigate = useNavigate();

  const filterByInput = () => {
    setEmployeeListFiltered(
      employeesList.filter((employee) => {
        return employee.name.toLowerCase().includes(input.toLowerCase());
      })
    );
  };

  useEffect(() => {
    filterUsers().then((res) => setEmployeeList(res.employees));
  }, []);

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-between mb-5 mt-5 flex-column flex-sm-row gap-3 align-items-sm-end">
        <div className="d-flex flex-column col col-md-6 mx-auto mx-sm-0">
          <label>Procurar colaborador</label>
          <div>
            <input
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              type="text"
            />
            <ButtonSearch onClick={filterByInput}>
              <CiSearch size={25}/>
            </ButtonSearch>
          </div>
        </div>

        <Button
          color="secondary"
          outline
          onClick={() => {
            navigate("/admin/create_user");
          }}
        >
          Criar usuarios
        </Button>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
          </tr>
        </thead>
        {employeeListFiltered.length > 0
          ? employeeListFiltered.map((employee, i) => {
              return (
                <tbody
                  role="button"
                  onClick={() => {
                    navigate(`/${employee.id}/`);
                  }}
                  key={i}
                >
                  <tr>
                    <td>{employee.name}</td>
                    <td>{cpfMask(employee.cpf)}</td>
                  </tr>
                </tbody>
              );
            })
          : employeesList.map((employee, i) => {
              return (
                <tbody
                  role="button"
                  onClick={() => {
                    navigate(`/admin/employees/${employee.id}`);
                  }}
                  key={i}
                >
                  <tr>
                    <td>{employee.name}</td>
                    <td>{cpfMask(employee.cpf)}</td>
                  </tr>
                </tbody>
              );
            })}
      </Table>
    </Container>
  );
};
