import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../header";
import { UserContext } from "../../../context/userContext";
import { cpfMask, numbersCpf } from "../../../utils/cpfMask";
import { normalizeCnpjNumber, numbersCnpj } from "../../../utils/cnpjMask";

export const PartnerId = ({}) => {
  const navigate = useNavigate()
  const { getUserById, deleteUser, updateUser } = useContext(UserContext);
  const { partner_id } = useParams();
  const [user, setUser] = useState({
    email: "",
    name: "",
    cpf: "",
    cnpj: "",
    firstName: "",
    updatedAt: "",
    group: "",
  });

  useEffect(() => {
    if (partner_id) {
      getUserById(partner_id).then((res) => setUser(res));
    }
  }, [partner_id]);

  const formik = useFormik({
    onSubmit: async (values) => {
      values.cnpj = numbersCnpj(values.cnpj);
      values.cpf = numbersCpf(values.cpf);
      values.group = values.group === "PARTNER" ? 3 : 2;
      updateUser(partner_id, values).then((res) => setUser(res.data));
      navigate('/admin/partners')
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        email: user.email,
        name: user.name,
        cpf: user.cpf,
        cnpj: user.cnpj,
        firstName: user.firstName,
        updatedAt: user.updatedAt,
        group: user.group,
      });
    }
  }, [user]);

  return (
    <>
      <Header></Header>
      <Container>
        <Row>
          <Col xs="12 pb-3">
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 align-center"
            >
              {user && (
                <>
                  <div>
                    <label>Nome</label>
                    <Input
                      value={user.name}
                      onChange={(e) => {
                        setUser({ ...user, name: e.target.value });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Email</label>
                    <Input
                      value={user.email}
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Cpf</label>
                    <Input
                      value={user.cpf}
                      type="tel"
                      onChange={(e) => {
                        setUser({ ...user, cpf: cpfMask(e.target.value) });
                      }}
                    ></Input>
                  </div>
                  <div>
                    <label>Cnpj</label>
                    <Input
                      type="tel"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          cnpj: normalizeCnpjNumber(e.target.value),
                        });
                      }}
                      value={user.cnpj}
                    ></Input>
                  </div>
                  <div>
                    <label>Grupo</label>
                    <select
                      value={user.group}
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setUser({ ...user, group: e.target.value });
                      }}
                    >
                      <option value={"PARTNER"}>Parceiro</option>
                      <option value={"EMPLOYEE"}>Funcionario</option>
                    </select>
                  </div>
                  <div>
                    <label>Primeiro nome</label>
                    <Input
                      value={user.firstName}
                      onChange={(e) => {
                        setUser({ ...user, firstName: e.target.value });
                      }}
                    ></Input>
                  </div>
                </>
              )}
              <Button size="lg" type="submit" color="danger">
                Atualizar
              </Button>
              <Button
                size="lg"
                onClick={() => {
                  deleteUser(partner_id).then(() => {
                    navigate('/admin/partners')
                  });
                }}
                color="danger"
              >
                Deletar
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
