import { Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { formatValueBrl } from "../../currencyInput.js";
export const Property = ({ list_property, group }) => {
  const navigate = useNavigate();

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th>CPF</th>
          <th>Status</th>
          <th>Valor do imóvel</th>
          <th>Financiado</th>
          <th>Modalidade</th>
          <th>Banco</th>
          <th>Data</th>
          <th>Etapa</th>
          <th>Observação</th>
        </tr>
      </thead>
      {list_property &&
        list_property.map((property) => {
          return (
            <tbody
              role="button"
              onClick={() => {
                navigate(`/${property.id}/${group}`);
              }}
            >
              <tr>
                <td>{property.name}</td>
                <td>{property.cpf}</td>
                <td>{property.status}</td>
                <td>{formatValueBrl(property.propertyValue)}</td>
                <td>{formatValueBrl(property.amountFinanced)}</td>
                <td>{property.typeOfOperation}</td>
                <td>{property.bank}</td>
                <td>{property.sendDate}</td>
                <td>{property.phase}</td>
                <td>{property.observation}</td>
              </tr>
            </tbody>
          );
        })}
    </Table>
  );
};
