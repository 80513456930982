import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  AdminHome,
  PartnerHome,
  EmployeeHome,
} from "../../components/homeGroups";
import { UserContext } from "../../context/userContext";
import { isEmpty } from "lodash";

export const Home = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const { user, token, getUser } = useContext(UserContext);
  
  useEffect(() => {
    if (isEmpty(user) && token === undefined) {
      return navigate("/login");
    }
    if (token) {
      getUser(token).then((user) => {
        switch (user.group) {
          case "ADMIN":
            setContent(<EmployeeHome user={user} />);
            return;
          case "EMPLOYEES":
            setContent(<EmployeeHome user={user} />);
            return;
          default:
            setContent(<PartnerHome user={user} />);
            return
        }
      });
    }
  }, []);
  return <>{content && content}</>;
};
