import lock from "../../assets/lock_icon.png";
import logo from "../../assets/logo_login.png";
import { Container, Logo, ContainerLogin, Modal } from "./styles";
import { useFormik } from "formik";
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";

export const Login = () => {
  const navigate = useNavigate();
  const { getUser, loginUser, setUser, setToken } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    onSubmit: (values) => {
      loginUser(values).then((data) => {
        Cookies.remove("auth_token");
        Cookies.set("auth_token", data.token, { expires: 7 });
        setToken(data.token);
        getUser(data.token).then(({ group }) => {
          if (group === "ADMIN") {
            return navigate("/admin");
          }
          return navigate("/");
        });
      }).catch((res) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo deu errado!",
        });
      });
    },
  });

  useEffect(() => {
    Cookies.remove("auth_token");
    setUser({});
    setToken(undefined);
  }, []);

  return (
    <Container>
      <Modal>
        <Logo src={logo} />
        <ContainerLogin
          onSubmit={(event) => {
            event.preventDefault();
            formik.handleSubmit(event);
          }}
        >
          <div>
            <label>Login</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
          </div>
          <div>
            <label>Senha</label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
          </div>
          <div className="lock">
            <button type="submit">
              <img src={lock} />
            </button>
          </div>
          <div className="send_mobile">
            <button type="submit" className="mobile__button">
              Login
            </button>
          </div>
        </ContainerLogin>
      </Modal>
    </Container>
  );
};
