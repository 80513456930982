export const CurrencyEvent = (e) => {
  const formatCurrency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const rawValue = e.target.value.replace(/\D/g, "");
  const finalValue = formatCurrency.format(parseFloat(rawValue / 100));
  
  e.target.value = finalValue;
  return e.target.value
};

export const getValueCurrency = (string) => {
  return string.replaceAll(".", "").replace(",", ".").replace("R$", "");
};

export const formatValueBrl = (value) => {
  if (value == null) {
    return "R$ 0.00"; 
  }
  const formatCurrency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  const rawValue = value.replace(/\D/g, "");
  const finalValue = formatCurrency.format(parseFloat(rawValue) / 100);
  
  return finalValue;
}
