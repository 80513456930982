import { createContext, useEffect, useState } from "react";
import { API } from "../api";
import Cookies from "js-cookie";
import camelcaseKeysDeep from "camelcase-keys-deep";
import { isEmpty } from "lodash";
import snakecaseKeys from "snakecase-keys";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [isFetched, setIsFetched] = useState(false);
  const [token, setToken] = useState(Cookies.get("auth_token"));

  const getUser = async (token) => {
    if (!token) {
      return "token must be provided";
    }
    const res = await API.get("users/retrieve_token/", {
      headers: { Authorization: `Token ${token}` },
    })
      .then(({ data }) => camelcaseKeysDeep(data))
      .catch(() => {
        setUser({});
        Cookies.remove("auth_token");
      });
    return res;
  };

  const loginUser = async (data) => {
    const res = await API.post("users/login/", data).then(({ data }) => {
      Cookies.set("auth_token", data.token, { expires: 7 });
      return data;
    });
    return res;
  };
  
  const logOutUser = async () => {
    setUser({})
    setIsFetched(false)
    Cookies.remove("auth_token");
  };
  const filterUsers = async () => {
    const res = await API.get("users", {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const getUserById = async (user_id) => {
    const res = await API.get(`/user/${user_id}/`, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const createUser = async (data) => {
    data = snakecaseKeys(data)
    const res = await API.post(`/users/register/`, data, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const updateUser = async (user_id, data) => {
    data = snakecaseKeys(data)
    const res = await API.patch(`/user/${user_id}/`, data, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const deleteUser = async (user_id) => {
    const res = await API.delete(`/user/${user_id}/`, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  };

  const createPassMail = async (user_id, data) => {
    const res = await API.post(`/users/create_pass_mail/${user_id}/`, data, {
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => camelcaseKeysDeep(data));
    return res;
  }

  useEffect(() => {
    if (isEmpty(user) && token && token !== undefined && isFetched === false) {
      getUser(token).then(setUser);
      setIsFetched(true);
    }
  }, [token]);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getUser,
        token,
        filterUsers,
        loginUser,
        logOutUser,
        getUserById,
        createUser,
        updateUser,
        deleteUser,
        createPassMail,
        setToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
