import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../header";
import camelcaseKeysDeep from "camelcase-keys-deep";
import { PropertyContext } from "../../../context/propertyContext";

export const PropertyId = ({}) => {
  const { route_id } = useParams();
  const [property, setProperty] = useState();
  const { getPropertyById, patchProperty} = useContext(PropertyContext)
  
  useEffect(() => {
    getPropertyById(route_id).then((res) => setProperty(camelcaseKeysDeep(res.data)));
  }, [route_id]);

  const formik = useFormik({
    initialValues: {
      observation: "",
    },
    onSubmit: async (values) => {
      patchProperty(`property/${property.id}`, values).then(({data}) => {
        setProperty(camelcaseKeysDeep(data))
      });
    },
  });

  useEffect(() => {
    if(property) formik.setFieldValue("observation", property.observation);
  }, [property]);


  return (
    <>
      <Header></Header>
      <Container responsive>
        <Row>
          <Col xs="12 pb-3">
            <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3 align-center">
              {property && (
                <>
                  <div>
                    <label>Nome</label>
                    <Input disabled value={property && property.name}></Input>
                  </div>
                  <div>
                    <label>Cpf</label>
                    <Input disabled value={property && property.cpf}></Input>
                  </div>
                  <div>
                    <label>Status</label>
                    <Input disabled value={property && property.status}></Input>
                  </div>
                  <div>
                    <label>Valor do imóvel</label>
                    <Input
                      disabled
                      value={property && property.propertyValue}
                    ></Input>
                  </div>
                  <div>
                    <label>Financiado</label>
                    <Input
                      disabled
                      value={property && property.amountFinanced}
                    ></Input>
                  </div>
                  <div>
                    <label>Modalidade</label>
                    <Input
                      disabled
                      value={property && property.typeOfOperation}
                    ></Input>
                  </div>
                  <div>
                    <label>Banco</label>
                    <Input disabled value={property && property.bank}></Input>
                  </div>
                  <div>
                    <label>Data</label>
                    <Input
                      disabled
                      value={property && property.sendDate}
                    ></Input>
                  </div>
                  <div>
                    <label>Etapa</label>
                    <Input disabled value={property && property.phase}></Input>
                  </div>
                  <div>
                    <label>Observação</label>
                    <Input
                      id="observation"
                      name="observation"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.observation}
                    ></Input>
                  </div>
                </>
              )}
              <Button size="lg" type="submit" color="danger">Atualizar</Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
