import { Routes } from "../routes";
import { UserProvider } from "./userContext";
import { PropertyProvider } from "./propertyContext";

export const Contexts = () => {
  return (
    <UserProvider>
      <PropertyProvider>
        <Routes />
      </PropertyProvider>
    </UserProvider>
  );
};
