import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { formatValueBrl } from "../../currencyInput.js";
import { cpfMask, numbersCpf } from "../../../utils/cpfMask.js";
import { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { ButtonSearch } from "../../groups/styles.js";
import Swal from "sweetalert2";

export const PropertyEmployee = ({ group, list_property = [] }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [searchStep, setSearchStep] = useState();
  const [filteredProperty, setFilteredProperty] = useState([]);

  const filterProperties = () => {
    const filtered = list_property.filter((elem) => elem.cpf.includes(numbersCpf(search)))
    if(filtered.length > 0){
      return setFilteredProperty(filtered);
    }
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Cpf nao encontrado",
    });
    setFilteredProperty([])
    setSearch('')
  };
  const filterSteps = (step) => {
    setFilteredProperty(
      list_property.filter((elem) => elem.phase.includes(step))
    );
  };

  return (
    <>
      <div>
        <label className="me-3">Procurar por cpf</label>
        <input
          value={search}
          onChange={(e) => {
            setSearch(cpfMask(e.target.value));
          }}
        ></input>
        <ButtonSearch onClick={filterProperties}>
          <CiSearch size={25} />
        </ButtonSearch>
      </div>
      <div className="mb-3">
        <label className="me-3 mt-3">Procurar por etapa</label>
        <select
          className="me-3"
          aria-label="Default select example"
          name="phase"
          value={searchStep}
          onChange={(e) => {
            setSearchStep(e.target.value);
            filterSteps(e.target.value)
          }}
        >
          <option value="" defaultValue>
            Selecione
          </option>
          <option value="Avaliação">Avaliação</option>
          <option value="Vistoria">Vistoria</option>
          <option value="Jurídico">Jurídico</option>
          <option value="Conformidade">Conformidade</option>
          <option value="Emissão do Contrato">
            Emissão do Contrato
          </option>
          <option value="Registro">Registro</option>
        </select>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Status</th>
            <th>Parceiro</th>
            <th>Valor do imóvel</th>
            <th>Financiado</th>
            <th>Nº Proposta</th>
            <th>Modalidade</th>
            <th>Banco</th>
            <th>Etapa</th>
            <th>Colaborador</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          {filteredProperty.length > 0
            ? filteredProperty.map((property, i) => (
              <tr
                role="button"
                onClick={() => {
                  navigate(`/${property.id}/${group}`);
                }}
                key={i}
              >
                <td>{property.name}</td>
                <td>{cpfMask(property.cpf)}</td>
                <td>{property.status}</td>
                <td>{property.responsible?.name}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={formatValueBrl(property.propertyValue)}
                >
                  {formatValueBrl(property.propertyValue)}
                </td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={formatValueBrl(property.amountFinanced)}
                >
                  {formatValueBrl(property.amountFinanced)}
                </td>
                <td>{property.proposalNumber}</td>
                <td>{property.typeOfOperation}</td>
                <td>{property.bank}</td>
                <td>{property.phase}</td>
                <td>{property.employee?.name}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={property.observation}
                >
                  {property.observation}
                </td>
              </tr>
            ))
            : list_property.map((property, i) => (
              <tr
                role="button"
                onClick={() => {
                  navigate(`/${property.id}/${group}`);
                }}
                key={i}
              >
                <td>{property.name}</td>
                <td>{cpfMask(property.cpf)}</td>
                <td>{property.status}</td>
                <td>{property.responsible?.name}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={formatValueBrl(property.propertyValue)}
                >
                  {formatValueBrl(property.propertyValue)}
                </td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={formatValueBrl(property.amountFinanced)}
                >
                  {formatValueBrl(property.amountFinanced)}
                </td>
                <td>{property.proposalNumber}</td>
                <td>{property.typeOfOperation}</td>
                <td>{property.bank}</td>
                <td>{property.phase}</td>
                <td>{property.employee?.name}</td>
                <td
                  style={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={property.observation}
                >
                  {property.observation}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};
