import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { Header } from "../../components/header";
import { UserContext } from "../../context/userContext";
import { cpfMask, numbersCpf } from "../../utils/cpfMask";
import { normalizeCnpjNumber, numbersCnpj } from "../../utils/cnpjMask";
import snakecaseKeys from "snakecase-keys";

export const CreateUser = ({}) => {
  const { createUser } = useContext(UserContext);
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      cpf: "",
      cnpj: "",
      firstName: "",
      password: "",
      group: "",
    },
    onSubmit: async (values) => {
      values.cnpj = numbersCnpj(values.cnpj);
      values.cpf = numbersCpf(values.cpf);
      values.group = values.group === "PARTNER" ? 3 : 2;
      values = snakecaseKeys(values)

      createUser(values).then((res) => {
        navigate('/admin')
      });
    },
  });

  return (
    <>
      <Header></Header>
      <Container>
        <Row>
          <Col xs="12 pb-3">
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 align-center"
            >
              <div>
                <label>Nome</label>
                <Input
                  required
                  value={formik.values.name}
                  name="name"
                  onChange={formik.handleChange}
                ></Input>
              </div>
              <div>
                <label>Email</label>
                <Input
                  required
                  value={formik.values.email}
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                ></Input>
              </div>
              <div>
                <label>Senha</label>
                <Input
                  required
                  value={formik.values.password}
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                ></Input>
              </div>
              <div>
                <label>Cpf</label>
                <Input
                  value={formik.values.cpf}
                  name="cpf"
                  type="tel"
                  onChange={(e) => {
                    e.target.value = cpfMask(e.target.value);
                    formik.handleChange(e);
                  }}
                ></Input>
              </div>
              <div>
                <label>Cnpj</label>
                <Input
                  value={formik.values.cnpj}
                  name="cnpj"
                  type="tel"
                  onChange={(e) => {
                    e.target.value = normalizeCnpjNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                ></Input>
              </div>
              <div>
                <label>Grupo</label>
                <select
                  value={formik.values.group}
                  name="group"
                  className="form-select"
                  aria-label="Default select example"
                  onChange={formik.handleChange}
                >
                  <option value="" defaultChecked disabled>Selecione</option>
                  <option value={"PARTNER"}>Parceiro</option>
                  <option value={"EMPLOYEES"}>Funcionario</option>
                </select>
              </div>
              <div>
                <label>Primeiro nome</label>
                <Input
                  value={formik.values.firstName}
                  name="firstName"
                  onChange={formik.handleChange}
                ></Input>
              </div>
              <Button size="lg" type="submit" color="success" outline>
                Criar usuario
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
