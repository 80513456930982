import { Property } from "./propertyPartner";
import { PropertyEmployee } from "./propertyEmployee";
import { PropertiesList } from "./styles";
import { useContext } from "react";
import { PropertyContext } from "../../context/propertyContext";

export const Properties = ({ group }) => {
  const { properties } = useContext(PropertyContext)
  return (
    <PropertiesList className="row g-0">
      <div className="p-3 p-md-5 col-12 ">
        {group === "PARTNER" && <Property list_property={properties} group={group}/>}
        {group === "EMPLOYEES" && <PropertyEmployee list_property={properties} group={group}/>}
        {group === "ADMIN" && <PropertyEmployee list_property={properties} group={group}/>}
      </div>
    </PropertiesList>
  );
};
