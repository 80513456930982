import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Button } from "reactstrap";
import { MdArrowBack } from "react-icons/md";

export const AdminHome = ({}) => {
  const navigate = useNavigate();
  return (
    <main style={{ display: "grid", height: "100vh", alignItems: "start" }}>
      <Container className="my-3">
        <Button
          outline
          onClick={() => {
            navigate("/");
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <MdArrowBack />
        </Button>
      </Container>
      <Container className="d-flex justify-content-center flex-column ">
        <h1
          role="button"
          onClick={() => {
            navigate("/admin/partners");
          }}
        >
          Parceiros
        </h1>

        <h1
          role="button"
          onClick={() => {
            navigate("/admin/employees");
          }}
        >
          Colaborador
        </h1>
      </Container>
    </main>
  );
};
