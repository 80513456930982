import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { Login } from "../pages/login/login";
import { Home } from "../pages/home/home";

import { PropertyId } from "../components/properties/propertyPartner/propertyId";
import { PropertyEmployeeId } from "../components/properties/propertyEmployee/propertyEmployeeId";
import { PropertyAdminId } from "../components/properties/propertyAdmin/propertyAdminId";
import { Employees } from "../components/groups/employees";
import { Partners } from "../components/groups/partners";
import { AdminHome } from "../components/homeGroups";
import { PartnerId } from "../components/groups/groupsId/partnerId";
import { EmployeeId } from "../components/groups/groupsId/WorkerId";
import { CreateUser } from "../pages/createUser/createUser";
import { PropertyCreate } from "../components/properties/propertyAdmin/propertyCreate";

const RenderPropertyByGroup = () => {
  const { route_id, group } = useParams();
  switch (group) {
    case "ADMIN":
      return <PropertyAdminId routeId={route_id} />;
    case "EMPLOYEES":
      return <PropertyEmployeeId routeId={route_id} />;
    default:
      return <PropertyId routeId={route_id} />;
  }
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/admin",
    element: <AdminHome />,
  },
  {
    path: "/admin/create_user",
    element: <CreateUser />,
  },
  {
    path: "/create_property",
    element: <PropertyCreate />,
  },
  {
    path: "/admin/partners",
    element: <Partners />,
  },
  {
    path: "/admin/partners/:partner_id",
    element: <PartnerId />,
  },
  {
    path: "/admin/employees",
    element: <Employees />,
  },
  {
    path: "/admin/employees/:employee_id",
    element: <EmployeeId />,
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/:route_id/:group",
    element: <RenderPropertyByGroup />,
    
  },
]);

export const Routes = () => {
  return <RouterProvider router={router}></RouterProvider>;
};
